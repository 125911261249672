<template>
  <div>
    <v-row justify="center">
      <v-col md="9">
        <v-card>
          <timer
            :durationMin="unmod.durationMin"
            :durationSec="unmod.durationSec"
          ></timer>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import timer from "../components/unmodTimer";

export default {
  metaInfo() {
    return {
      title: this.$t("unmod"),
    };
  },
  props: {
    // unmod: {
    //   type: Object,
    //   default: function () {
    //     return {
    //       durationMin: 10,
    //       durationSec: 0,
    //       country: {
    //         code: "AFG",
    //         name_zh: "阿富汗",
    //         name_en: "Afghanistan",
    //         status: "PV",
    //         on_GSL: false,
    //         vote_status: "abstain",
    //         power_status: "Delegate",
    //         on_mod: false,
    //       },
    //       type: "unmod",
    //     };
    //   },
    // },
  },
  components: {
    timer,
  },
  data() {
    return {
      time: 60000,
    };
  },
  computed: {
    unmod() {
      return this.$store.state.currentSession.currentUnmod;
    },
    countriesPresent() {
      return this.$store.getters.inSessionCountriesPresent;
    },
  },
  methods: {
    remove(item) {
      console.log(item);
      const index = this.speakers.findIndex((x) => x.name_zh === item.name_zh);
      console.log(index);
      if (index >= 0) this.speakers.splice(index, 1);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.currentSession.unmodtimerState === "finished") {
      next();
    } else {
      const answer = window.confirm(this.$t("confirmLeaving"));
      if (answer) {
        next();
      } else {
        next(false);
      }
    }
  },
};
</script>
<i18n>
{
  "en": {
    "unmod": "Unmoderated Caucus",
    "confirmLeaving": "Leaving this page will restart the timer. Are you sure?"
  },
  "zh": {
    "unmod": "无组织核心磋商",
    "confirmLeaving":"确认离开么？计时将重置"
  }
}
</i18n>