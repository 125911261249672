<template>
  <div>
    <v-card elevation="0">
      <v-card-text>
        <v-card height="400px">
          <v-card-text class="text-center">
            <v-card-title primary-title> </v-card-title>
            <v-card-title primary-title> </v-card-title>
            <v-card-title primary-title> </v-card-title>
            <v-card-title primary-title> </v-card-title>
            <vac :left-time="leftTime" :a5uto-start="false" ref="vac1">
              <span slot="before">
                <div class="text-h1">
                  {{ timer.min | doubleDigits }}:{{
                    timer.sec | doubleDigits
                  }}
                  / {{ timer.min | doubleDigits }}:{{
                    timer.sec | doubleDigits
                  }}
                </div>
              </span>
              <span slot="process" slot-scope="{ timeObj }">
                <div class="text-h1">
                  {{ timeObj.m }}:{{ timeObj.s }} /
                  {{ timer.min | doubleDigits }}:{{ timer.sec | doubleDigits }}
                </div>
              </span>
              <span slot="finish">
                <div class="text-h1 red--text">
                  <span class="blinking"
                    >00:00/{{ timer.min | doubleDigits }}:{{
                      timer.sec | doubleDigits
                    }}</span
                  >
                </div>
              </span>
            </vac>
          </v-card-text>
        </v-card>

        <v-card-actions class="justify-center">
          <v-row>
            <v-col md="4">
              <v-btn color="secondary" block @click="switchCountdown">
                <v-icon>{{ timerIcon }}</v-icon>
                {{ timerText }}
              </v-btn>
            </v-col>
            <v-col md="4">
              <v-btn color="secondary" block @click="resetCountdown">
                <v-icon>mdi-restore</v-icon>{{ $t("restart") }}
              </v-btn>
            </v-col>

            <v-col md="4">
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="secondary"
                    block
                    v-on="on"
                    :disabled="!finished"
                  >
                    <v-icon>mdi-plus</v-icon>{{ $t("extend") }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ $t("extendMin") }}</span>
                  </v-card-title>
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    :disabled="!finished"
                  >
                    <v-card-text>
                      <v-container grid-list-md>
                        <v-layout wrap>
                          <v-slider
                            thumb-label="always"
                            v-model="extensionMin"
                            color="secondary"
                            track-color="grey"
                            always-dirty
                            min="0"
                            max="30"
                          ></v-slider>
                        </v-layout>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="secondary" @click.native="dialog = false">{{
                        $t("cancel")
                      }}</v-btn>
                      <v-btn
                        color="secondary"
                        @click.native="extend"
                        :disabled="!valid"
                        >{{ $t("confirm") }}</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "unmod-timer",
  props: {
    durationMin: {
      default: 10,
    },
    durationSec: {
      default: 0,
    },
  },
  filters: {
    doubleDigits: function (val) {
      const formattedNumber = ("0" + val).slice(-2);
      return formattedNumber;
    },
  },
  data() {
    return {
      dialog: false,
      valid: true,
      timerIcon: "mdi-play",
      timerText: "",
      secondRules: [(v) => (/^\d+$/.test(v) && v <= 60) || this.$t("input60")],
      minRules: [(v) => (/^\d+$/.test(v) && v <= 60) || this.$t("input60")],
      leftTime: 0,
      timer: {
        min: 0,
        sec: 5,
      },
      extensionMin: 5,
      finished: false,
    };
  },
  computed: {},
  methods: {
    resetCountdown() {
      const vm = this;
      vm.$refs.vac1.startCountdown(true);
    },
    switchCountdown() {
      console.log(this.$refs.vac1.state);
      const vm = this;
      if (
        vm.$refs.vac1.state === "paused" ||
        vm.$refs.vac1.state === "beforeStart"
      ) {
        vm.$refs.vac1.startCountdown();
      } else {
        vm.$refs.vac1.pauseCountdown();
      }
      vm.$nextTick(() => {
        vm.timerIcon =
          vm.$refs.vac1.state === "paused" ? "mdi-play" : "mdi-stop";
        vm.timerText =
          vm.$refs.vac1.state === "paused"
            ? this.$t("continue")
            : this.$t("pause");
      });
    },
    continueCountdown() {
      const vm = this;
      vm.$refs.vac1.startCountdown();
    },
    stopCountdown() {
      const vm = this;
      vm.$refs.vac1.pauseCountdown();
    },
    extend() {
      this.dialog = false;
      this.leftTime = this.extensionMin * 60000;
      console.log(this.durationMin);
      console.log(this.extensionMin);
      this.durationMin =
        parseInt(this.durationMin) + parseInt(this.extensionMin);
      this.timer = {
        min: this.durationMin,
        sec: this.durationSec,
      };
      this.$nextTick(() => {
        this.$refs.vac1.startCountdown(true);
      });
    },
  },
  mounted() {
    this.timerText = this.$t("continue");
    this.$watch(
      () => {
        return this.$refs.vac1.state;
      },
      (val) => {
        this.$store.commit("setUnmodtimerState", val);
        if (val === "process") {
          this.timerIcon = "mdi-stop";
          this.timerText = this.$t("pause");
        }
        this.finished = this.$refs.vac1.state === "finished";
      }
    );
  },
  created() {
    this.leftTime = 1000 * this.durationSec + 60000 * this.durationMin;
    this.timer = {
      min: this.durationMin,
      sec: this.durationSec,
    };
  },
};
</script>

<style scoped>
.blinking {
  animation: blinkingText 1.2s infinite;
}
@keyframes blinkingText {
  0% {
    color: #d32f2f;
  }
  49% {
    color: #d32f2f;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #d32f2f;
  }
}
</style>


<i18n>
{
  "en": {
    "restart": "Restart",
    "speakingTime": "Speaking Time",
    "min": "Minute",
    "sec": "Second",
    "cancel": "Cancel",
    "extend": "Extend",
    "continue": "Continue",
    "input60": "Please Input Integer within 60 ",
    "extendMin": "Extend Unmoderated Caucus (Minutes)",
    "confirm":"Confirm",
    "pause": "Pause"
  },
  "zh": {
    "restart": "重新开始",
    "speakingTime": "发言时长",
    "sec": "秒",
    "min": "分钟",
    "cancel": "取消",
    "input60": "输入60以内整数",
    "extend": "延长",
    "continue": "继续",
    "extendMin": "延长时间（分钟）",
    "confirm":"确认",
    "pause": "暂停"
  }
}
</i18n>